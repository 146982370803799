import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
// import * as echarts from "echarts/core";
import * as echarts from "echarts";

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
} from "echarts";
import { CustomChart } from "echarts";
import { CanvasRenderer } from "echarts";

// echarts.use([
//   TitleComponent,
//   TooltipComponent,
//   GridComponent,
//   DataZoomComponent,
//   CustomChart,
//   CanvasRenderer,
// ]);
@Component({
  selector: "app-ganttchart",
  templateUrl: "./ganttchart.component.html",
  styleUrls: ["./ganttchart.component.scss"],
})

/**
 * E-chart component
 */
export class GanttchartComponent implements OnInit, AfterViewInit {
  @Input() gaugeArray: any;
  echartsInstance: any;
  constructor(private elementRef: ElementRef) {}

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Charts" },
      { label: "E - Chart", active: true },
    ];

    var data = {
      time: this.gaugeArray[0]["time"],
      value: this.gaugeArray[1]["overall_status"],
    };
    if (this.gaugeArray[0]["time"][0].length != 0) {
      var data = {
        time: this.gaugeArray[0]["time"],
        value: this.gaugeArray[1]["overall_status"],
      };
      this.chartData(data);
      // this.gaugeChart(this.data);
    }
    console.log(this.gaugeArray, data);
    // this.chartData();
  }
  options: any;
  ngOnChanges(): void {
    console.log(
      // this.gaugeArray[0]["time"][0],
      this.gaugeArray,
      "resss.............."
    );
    if (this.gaugeArray[0]["time"][0].length != 0) {
      var data = {
        time: this.gaugeArray[0]["time"],
        value: this.gaugeArray[1]["overall_status"],
      };
      console.log(data, "resss..............");
      this.chartData(data);

      // this.gaugeChart(this.gaugeArray);
      // this.gaugeChart(this.data);
    }
  }
  ngAfterViewInit(): void {}
  chartData(data) {
    console.log(data["value"][0], "resss..............");
    this.options = {
      xAxis: {
        type: "category",
        data: data["time"][0],
      },
      yAxis: {
        type: "value",
        show: false,
        grid: false,
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          console.log(params, "resss..............");
          let tooltipContent = "";
          params.forEach(function (item) {
            console.log(item, "resss..............");
            tooltipContent += `${item.axisValue}: ${item.name.toUpperCase()}`;
          });
          return tooltipContent;
          // Customize tooltip content: Show name and value
        },
      },
      dataZoom: [
        {
          start: 50,
          end: 100,
        },
      ],
      series: [
        {
          data: data["value"][0],
          barWidth: "100%", // Use full width for each bar
          barGap: "0%", // Remove gap between bars in the same series
          barCategoryGap: "0%",
          type: "bar",
        },
      ],
    };
  }
}
