<div class="container-fluid">
  <!-- <app-pagetitle
    title="Dashboard"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle> -->
  <div class="row">
    <div class="col-md-12">
      <div class="page-title-box d-flex justify-content-between">
        <h4 class="mb-0">Dashboard</h4>
        <ui-switch
          style="display: none"
          defaultBoColor="#dfdfdf"
          style="display: none"
          size="small"
          color="#00b19d"
          [(ngModel)]="enable"
          (change)="toggleMe($event, enable)"
        ></ui-switch>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12">
      <div class="row">
        <!-- <div *ngFor="let stat of statData" class="col-md-3"> -->
        <div class="col-md-2">
          <div class="card">
            <div class="card-body" style="padding: 11px !important">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Plant Efficency</p>
                  <h5 class="mb-0">
                    {{ m_effic ? (m_effic | fixedDecimal) : 0 }} %
                  </h5>
                </div>
                <div class="text-primary">
                  <i class="ri-settings-line font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="card">
            <div class="card-body" style="padding: 11px !important">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">
                    Plant Performance
                  </p>
                  <h5 class="mb-0">{{ perf ? (perf | fixedDecimal) : 0 }} %</h5>
                </div>
                <div class="text-primary">
                  <i class="ri-settings-line font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card-body" style="padding: 11px !important">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Prod Loss</p>
                  <h5 class="mb-0">
                    {{ prodLoss ? (prodLoss | fixedDecimal) : 0 }} Rs
                  </h5>
                </div>
                <div class="text-primary">
                  <i class="ri-refund-2-line font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card-body" style="padding: 11px !important">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Energy Cost</p>
                  <h5 class="mb-0">
                    {{ energycost ? (energycost | fixedDecimal) : 0 }} Rs
                  </h5>
                </div>
                <div class="text-primary">
                  <i class="ri-water-flash-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="card">
            <div class="card-body" style="padding: 11px !important">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Total Current</p>
                  <h5 class="mb-0">{{ current ? current : 0 }} amps</h5>
                </div>
                <div class="text-primary">
                  <i class="ri-wireless-charging-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-2">
          <div class="card">
            <div class="card-body" style="padding: 11px !important">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">
                    Prod_cost (Hourly)
                  </p>
                  <h5 class="mb-0">{{ parts ? parts : 0 }}</h5>
                </div>
                <div class="text-primary">
                  <i class="ri-inbox-unarchive-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-md-2">
          <div class="card">
            <div class="card-body" style="padding: 11px !important">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Unit Cost</p>
                  <h5 class="mb-0">
                    {{ current ? (unitcost(current) | fixedDecimal) : 0 }}
                  </h5>
                </div>
                <div class="text-primary">
                  <i class="ri-refund-2-line font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-md-2">
          <div class="card">
            <div class="card-body" style="padding: 11px !important">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Total Parts</p>
                  <h5 class="mb-0">{{ parts ? parts : 0 }}</h5>
                </div>
                <div class="text-primary">
                  <i class="ri-inbox-unarchive-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <!-- end row -->
    </div>

    <!-- <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="float-right">
            <select class="custom-select custom-select-sm">
              <option selected>Apr</option>
              <option value="1">Mar</option>
              <option value="2">Feb</option>
              <option value="3">Jan</option>
            </select>
          </div>
          <h4 class="card-title mb-4">Sales Analytics</h4>

          <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics.series" [chart]="salesAnalytics.chart"
            [legend]="salesAnalytics.legend" [colors]="salesAnalytics.colors" [labels]="revenueChart.labels"
            [dataLabels]="salesAnalytics.dataLabels" [plotOptions]="salesAnalytics.plotOptions">
          </apx-chart>
          <div class="row">
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> Product A
                </p>
                <h5>42 %</h5>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Product B
                </p>
                <h5>26 %</h5>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-warning font-size-10 mr-1"></i> Product C
                </p>
                <h5>42 %</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="dropdown float-right" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown"
              aria-expanded="false" ngbDropdownToggle>
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              
              <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
              
              <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
              
              <a href="javascript:void(0);" class="dropdown-item">Profit</a>
             
              <a href="javascript:void(0);" class="dropdown-item">Action</a>
            </div>
          </div>

          <h4 class="card-title mb-4">Earning Reports</h4>
          <div class="text-center">
            <div class="row">
              <div class="col-sm-6">
                <div>
                  <div class="mb-3">
                    <apx-chart dir="ltr" class="apex-charts" [series]="sparklineEarning.series"
                      [chart]="sparklineEarning.chart" [colors]="sparklineEarning.colors"
                      [labels]="sparklineEarning.labels" [dataLabels]="sparklineEarning.dataLabels"
                      [plotOptions]="sparklineEarning.plotOptions">
                    </apx-chart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Weekly Earnings</p>
                  <h5 class="mb-0">$2,523</h5>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="mt-5 mt-sm-0">
                  <div class="mb-3">
                    <apx-chart dir="ltr" class="apex-charts" [series]="sparklineMonthly.series"
                      [chart]="sparklineMonthly.chart" [colors]="sparklineMonthly.colors"
                      [labels]="sparklineMonthly.labels" [dataLabels]="sparklineMonthly.dataLabels"
                      [plotOptions]="sparklineMonthly.plotOptions">
                    </apx-chart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Monthly Earnings</p>
                  <h5 class="mb-0">$11,235</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- end row -->
  <div class="row" *ngIf="!showGraph">
    <div
      class="col-md-6 col-xl-4"
      *ngFor="let data of machine_List; let i = index"
    >
      <!-- Simple card -->
      <div class="card">
        <div class="card-body">
          <div
            class="spinner-grow m-1"
            role="status"
            [style.color]="statusColor(data.status)"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <!-- <div class="row">
            <div class="col-md-12">
              <img style="width: 100%" src="../../../assets/Machine.png" />
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-8">
              <h4 class="card-title mt-0">
                {{ data.device_name }}
              </h4>
              <span> {{ data.deviceType ? data.deviceType : "-" }} </span>
            </div>
            <div class="col-md-4 time">
              <div class="btn-group" ngbDropdown>
                <button
                  class="btn btn-sm dropdown-toggle"
                  style="padding: 0"
                  type="button"
                  ngbDropdownToggle
                >
                  <i class="ri-compass-4-line font-size-24"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);"
                    ><span style="color: #007d79"> LUT </span> [
                    {{ epochToHHMMSS(data.LUT) }} ]</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="row"
            (click)="
              redirctTO(data.device_id, data.device_name, data.deviceType)
            "
          >
            <div class="col-md-4 col-sm-4" style="margin: auto">
              Part Count
              <h4>
                {{ getPartCount(data) }}
                <!-- <span style="color: #007d79">amps </span> -->
              </h4>
            </div>

            <div class="col-md-4 col-sm-4" style="margin: auto">
              M-Efficency
              <h4>
                {{ data.oee ? data.oee : 0 }}
                <span style="color: #007d79">% </span>
              </h4>
              <!-- <apx-chart
                dir="ltr"
                class="apex-charts"
                [series]="sparklineEarning[i].series"
                [chart]="sparklineEarning[i].chart"
                [colors]="sparklineEarning[i].colors"
                [labels]="sparklineEarning[i].labels"
                [dataLabels]="sparklineEarning[i].dataLabels"
                [plotOptions]="sparklineEarning[i].plotOptions"
              >
              </apx-chart> -->
              <!-- <p>M-Efficency: {{ data.oee ? data.oee : 0 }} %</p> -->
            </div>
            <div class="col-md-4 col-sm-4" style="margin: auto">
              Current
              <h4>
                {{ data.current ? data.current : 0 }}
                <span style="color: #007d79">amps </span>
              </h4>
            </div>
          </div>
          <div
            (click)="
              redirctTO(data.device_id, data.device_name, data.deviceType)
            "
            class="row"
            style="cursor: pointer !important"
          >
            <div class="col-md-4 col-sm-3">
              <span class="txt-font"> R-Power </span>
              <h6>
                {{ data.real_power ? data.real_power : 0 }}
                <span class="txt-font">W </span>
              </h6>
            </div>
            <div class="col-md-4 col-sm-3">
              <span class="txt-font"> A-Power </span>

              <h6>
                {{ data.apparent_power ? data.apparent_power : 0 }}
                <span class="txt-font">VA </span>
              </h6>
            </div>
            <!-- <div class="col-md-3 col-sm-3">
              <span class="txt-font"> Energy </span>

              <h6>
                {{ data.energy_consumption ? data.energy_consumption : 0 }}
                <span class="txt-font">KwH </span>
              </h6>
            </div> -->
            <div class="col-md-4 col-sm-3">
              <span class="txt-font"> T-Energy </span>

              <h6>
                {{ data.total_energy ? data.total_energy : 0 }}
                <span class="txt-font">KwH </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end col -->
  </div>
  <div class="row" *ngIf="showGraph">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <app-graphview></app-graphview>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-12">
      <app-combinechart></app-combinechart>
    </div>
  </div> -->
</div>
