<div class="container-fluid">
  <app-pagetitle
    title="Dashboard"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>
  <!-- <div class="row">
    <div class="col-xl-12">
      <div class="row">
        </!-- <div *ngFor="let stat of statData" class="col-md-3"> -->
  <!-- <div class="col-md-2">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Total Customer</p>
                  <h4 class="mb-0">
                    {{ customerList ? customerList.length : 0 }}
                  </h4>
                </div>
                <div class="text-primary">
                  <i class="ri-award-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div> --/>
        <div class="col-md-2">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Total Machine</p>
                  <h4 class="mb-0">
                    {{ totalMachine ? totalMachine.length : 0 }} /
                    <span style="color: blue">
                      {{ off_machine ? off_machine.length : 0 }}</span
                    >
                  </h4>
                </div>
                <div class="text-primary">
                  <i class="ri-settings-line font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Idle Machine</p>
                  <h4 class="mb-0">
                    {{ idle ? idle.length : 0 }}
                  </h4>
                </div>
                <div style="color: #f7c030">
                  <i class="ri-wireless-charging-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Dryrun Machine</p>
                  <h4 class="mb-0">
                    {{ dry_run ? dry_run.length : 0 }}
                  </h4>
                </div>
                <div style="color: #ffff94">
                  <i class="ri-wireless-charging-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Active Machine</p>
                  <h4 class="mb-0" style="color: green">
                    {{ running ? running.length : 0 }}
                  </h4>
                </div>
                <div style="color: #548237 !important">
                  <i class="ri-wireless-charging-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Warning Machine</p>
                  <h4 class="mb-0" style="color: green">
                    {{ warning ? warning.length : 0 }}
                  </h4>
                </div>
                <div style="color: #bfe0a5">
                  <i class="ri-wireless-charging-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Alarm Machine</p>
                  <h4 class="mb-0" style="color: green">
                    {{ alarm ? alarm.length : 0 }}
                  </h4>
                </div>
                <div style="color: #ee4932">
                  <i class="ri-wireless-charging-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        </!-- </div> --
      </div>
      - end row 
    </div>
  </div> -->
  <div class="container-fluid">
    <div class="d-flex justify-content-end">
      <div class="text-md-right">
        <!-- <div class="d-inline-block mr-2">
          <p class="badge" style="background-color: #f7c030; color: white">
            Idle
          </p>
        </div> -->
        <!-- <div class="d-inline-block mr-2">
          <p class="badge" style="background-color: #ffff94">Dryrun</p>
        </div> -->
        <div class="d-inline-block mr-2">
          <p class="badge" style="background-color: #548237; color: white">
            Running
          </p>
        </div>
        <!-- <div class="d-inline-block mr-2">
          <p class="badge" style="background-color: #bfe0a5">warning</p>
        </div> -->

        <div class="d-inline-block mr-2">
          <p class="badge" style="background-color: #ee4932; color: white">
            Off
          </p>
        </div>
      </div>
      <button
        type="button"
        class="btn"
        style="background-color: #007d79; color: white"
        (click)="refresh()"
      >
        <i class="ri-exchange-funds-line align-middle mr-2"></i>
        Refresh
      </button>
      <div class="example-button-row ml-2">
        <select
          class="form-control"
          [(ngModel)]="selectedCustomerId"
          (ngModelChange)="onChange($event)"
        >
          <!-- <option value="All" selected>All</option> -->
          <option *ngFor="let custId of customerList" [ngValue]="custId">
            {{ custId.custName }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="showMe">
    <div
      class="col-md-6 col-xl-4"
      *ngFor="let data of machine_List; let i = index"
    >
      <!-- Simple card -->
      <div class="card">
        <div class="card-body">
          <div
            class="spinner-grow m-1"
            role="status"
            [style.color]="statusColor(data.status)"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div class="row">
            <div class="col-md-8">
              <h4 class="card-title mt-0">
                {{ data.device_name }}
              </h4>
              <span>{{ data.deviceType }}</span>
            </div>
            <div class="col-md-4 time">
              <div class="btn-group" ngbDropdown>
                <button
                  class="btn btn-sm dropdown-toggle"
                  style="padding: 0"
                  type="button"
                  ngbDropdownToggle
                >
                  <i class="ri-compass-4-line font-size-24"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);"
                    ><span style="color: #007d79"> LUT </span> [
                    {{ epochToHHMMSS(data.LUT) }} ]</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- <h4 class="card-title mt-0">
            {{ data.device_name }} <br />
            <span style="color: #007d79"> LUT </span> [
            {{ epochToHHMMSS(data.LUT) }} ]
          </h4>
          <div
            class="spinner-grow m-1"
            role="status"
            [style.color]="statusColor(data.status)"
          >
            <span class="sr-only">Loading...</span>
          </div> -->
          <div
            class="row"
            (click)="redirctTO(data)"
            style="cursor: pointer !important"
          >
            <div class="col-md-4 col-sm-4" style="margin: auto">
              Part Count
              <h5>
                {{ getPartCount(data) }}
                <!-- <span style="color: #007d79">amps </span> -->
              </h5>
            </div>

            <div class="col-md-4 col-sm-4" style="margin: auto">
              M-Efficency
              <h5>
                {{ data.oee ? data.oee : 0 }}
                <span style="color: #007d79">% </span>
              </h5>
              <!-- <apx-chart
                dir="ltr"
                class="apex-charts"
                [series]="sparklineEarning[i].series"
                [chart]="sparklineEarning[i].chart"
                [colors]="sparklineEarning[i].colors"
                [labels]="sparklineEarning[i].labels"
                [dataLabels]="sparklineEarning[i].dataLabels"
                [plotOptions]="sparklineEarning[i].plotOptions"
              >
              </apx-chart> -->
              <!-- <p>M-Efficency: {{ data.oee ? data.oee : 0 }} %</p> -->
            </div>
            <div class="col-md-4 col-sm-4" style="margin: auto">
              Current
              <h5>
                {{ data.current ? data.current : 0 }}
                <span style="color: #007d79">amps </span>
              </h5>
            </div>
          </div>
          <div
            class="row"
            (click)="redirctTO(data)"
            style="cursor: pointer !important"
          >
            <div class="col-md-4 col-sm-3">
              <span class="txt-font"> R-Power </span>
              <h6>
                {{ data.real_power ? data.real_power : 0 }}
                <span class="txt-font">W </span>
              </h6>
            </div>
            <div class="col-md-4 col-sm-3">
              <span class="txt-font"> A-Power </span>

              <h6>
                {{ data.apparent_power ? data.apparent_power : 0 }}
                <span class="txt-font">VA </span>
              </h6>
            </div>
            <!-- <div class="col-md-3 col-sm-3">
              <span class="txt-font"> Energy </span>

              <h6>
                {{ data.energy_consumption ? data.energy_consumption : 0 }}
                <span class="txt-font">KwH </span>
              </h6>
            </div> -->
            <div class="col-md-4 col-sm-3">
              <span class="txt-font"> T-Energy </span>

              <h6>
                {{ data.total_energy ? data.total_energy : 0 }}
                <span class="txt-font">KwH </span>
              </h6>
            </div>
          </div>
        </div>
        <div
          class="card-footer"
          style="background-color: #0e7d79; color: white"
        >
          Device Type :<span style="color: #f7c030">
            {{ data.meter_supplier }}</span
          >
        </div>
      </div>
    </div>

    <!-- end col -->
  </div>
  <div *ngIf="!showMe" class="row mt-2 text-center">
    <img src="assets/images/noData.jpg" class="imgs" />
    Please wait we are fetching data / nodata
  </div>
  <!-- end row -->
</div>
