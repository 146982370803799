import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";

import * as echarts from "echarts";
// import * as moment from "moment-timezone";
// import { start } from "repl";

@Component({
  selector: "app-energychart",
  templateUrl: "./energychart.component.html",
  styleUrls: ["./energychart.component.scss"],
})

/**
 * E-chart component
 */
export class EnergyChartComponent implements OnInit, OnChanges {
  @Input() gaugeArray: any;

  constructor(private elementRef: ElementRef) {}
  data = {
    timeData: [
      "15:02:00",
      "15:03:00",
      "15:04:00",
      "15:05:00",
      "15:06:00",
      "15:07:00",
      "15:08:00",
      "15:09:00",
      "15:10:00",
      "15:11:00",
      "17:00:00",
      "17:05:00",
      "17:06:00",
      "17:07:00",
    ],
    // parts: [2,, 2, 2, 2];

    status: [1, 0, 1, 1, 1, 0, 0, 0, 0, 0, 1, 0, 0, 1],
    current: [10, 55, 3, 46, 24, 666, 3, 56, 4, 33, 32, 25, 41, 36],
    parts: [22, 11, 3, 623, 47, 666, 48, 56, 4, 68, 5, 28, 1, 15],
  };
  echartsInstance: any;

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Charts" },
      { label: "E - Chart", active: true },
    ];
    console.log(this.data, "combined data");
    if (this.gaugeArray[0]["time"][0].length != 0) {
      this.gaugeChart(this.gaugeArray);
      // this.gaugeChart(this.data);
    }
  }
  ngOnChanges(): void {
    console.log(
      // this.gaugeArray[0]["time"][0],
      this.gaugeArray[0],
      "resss.............."
    );
    if (this.gaugeArray[0]["time"][0].length != 0) {
      this.gaugeChart(this.gaugeArray);
      // this.gaugeChart(this.data);
    }
  }
  /**
   * Fetch the chart data
   */
  options: any;

  gaugeChart(data) {
    // let today = moment(new Date()).format("YYYY-MM-DD");
    // var overall_status = data[0].time[0].map((time, index) => {
    //   const dateStr = `${today}T${time}`;
    //   return [dateStr, data[1].overall_status[0][index].value];
    // });
    // console.log(overall_status, "overall_status");
    // var current = data[0].time[0].map((time, index) => {
    //   const dateStr = `${today}T${time}`;
    //   return [dateStr, data[0].current[0][index]];
    // });
    const start =
      ((data[0]["current"][0].length - 4) / data[0]["current"][0].length) * 100; // Calculate start percentage
    const end = 100;
    this.options = {
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          // Format the value displayed in the tooltip to two decimal places
          let tooltipContent = `${params[0].axisValueLabel}: `;
          tooltipContent += params
            .map(function (item) {
              return ` ${item.value.toFixed(2)} amps`; // Format each value to two decimals
            })
            .join("<br>");
          return tooltipContent;
        },
        // position: function (pt) {
        //   return [pt[0].toFixed(2), "10%"];
        // },
      },
      // title: {
      //   // start: "start",
      //   text: "Live Current",
      // },
      xAxis: {
        type: "category",
        data: data[0]["time"][0],
        boundaryGap: false,
      },
      yAxis: {
        type: "value",
        show: false,
        grid: false,
        boundaryGap: [0, "100%"],
      },

      dataZoom: [
        {
          type: "inside",
          start: start,
          end: end,
        },
      ],
      series: [
        {
          data: data[0]["current"][0],
          type: "line",
          // smooth: true,
          symbol: "none",
          sampling: "lttb",
          itemStyle: {
            color: "rgb(255, 70, 131)",
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(255, 158, 68)",
              },
              {
                offset: 1,
                color: "rgb(255, 70, 131)",
              },
            ]),
          },
        },
      ],
      // option && this.echartsInstance.setOption(option);
    };
  }
}
